<template>
  <div class="budget__card">
    <div :class="percentage ? '' : 'not-available-card'">
      <img
        src="../../assets/images/img_water_sanitation_budget.svg"
        alt="पानी र सरसफाइ"
        width="75"
        height="85"
      />
      <h3 class="mb-16">पानी र सरसफाइ क्षेत्र</h3>
    </div>
    <div class="budget-bar mb-16" v-if="percentage">
      <span class="value" :style="{ width: percentage + '%' }"></span>
    </div>
    <p class="amount" v-if="percentage != ''">{{ data }}</p>
  </div>
</template>
<script>
export default {
  name: "waterSector",
  props: {
    data: { type: String, required: true },
    percentage: { type: String, required: true },
  },
};
</script>
